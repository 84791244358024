import { Container } from "@material-ui/core";
import React from "react";

function iframe() {
  return {
    __html:
      '<iframe src="./files/policy.html" width="100%" height="800px"></iframe>',
  };
}

export const Privacy = () => {
  return (
    <Container
      id="privacy-container"
      sx={{ height: "100vh", background: "#000" }}
    >
      <div dangerouslySetInnerHTML={iframe()} />
    </Container>
  );
};
