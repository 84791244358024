import { Container, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { infoStyles } from './infoStyles' 

const useStyles = makeStyles((theme) => infoStyles(theme))

export const InfoSection = ({ id, imgStart, headline, description, description2, img, alt }) => {
    
    const classes = useStyles()
    
    return (
        <Container id={ id } className={ classes.container }>
            <Grid container 
                className={ classes.grid }
                spacing={6} 
                direction={(imgStart) ? 'row-reverse' : 'row'}
                justifyContent='center' 
                alignItems='center'>
                    <Grid item xs={12} md={6} className={ classes.item }>
                        <Typography
                            className={ classes.title } 
                            variant='h3' 
                            color='secondary'
                            align='center'>
                                { headline }
                        </Typography>
                        <Typography
                            className={ classes.description }
                            color='textSecondary'
                            align='center'>
                                { description }
                        </Typography>
                        <Typography
                            className={ classes.description }
                            color='textSecondary'
                            align='center'>
                                { description2 }
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={ classes.item }>
                        <Container>
                            <img className={ classes.image } src={ img }/>
                        </Container>
                    </Grid>
            </Grid>
        </Container>
    )
}
