import React from 'react'
import { DevelopedBy, FooterContainer, FooterLogo, FooterWrapper, LogoImg, SocialIconLink, SocialIcons, WebsiteRigths } from './FooterElements'
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa'
import { animateScroll as scroll } from 'react-scroll'
import imgBlanco from '../../images/sappito_tech_iso.png'

export const Footer = () => {

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
        <FooterContainer>
            <FooterWrapper>
                <FooterLogo to='/' onClick={ toggleHome }>
                    <LogoImg src={ imgBlanco } />
                </FooterLogo>
                <WebsiteRigths>Sappito Tech © { new Date().getFullYear() } All rights reserved.</WebsiteRigths>
                <SocialIcons>
                    {/* <SocialIconLink href='/' target='_blank' arial-label='Facebook'>
                        <FaFacebook/>
                    </SocialIconLink> */}
                    <SocialIconLink href='//instagra.com/sappitotech' target='_blank' arial-label='Instagram'>
                        <FaInstagram/>
                    </SocialIconLink>
                    {/* <SocialIconLink href='/' target='_blank' arial-label='Twitter'>
                        <FaTwitter/>
                    </SocialIconLink>
                    <SocialIconLink href='/' target='_blank' arial-label='Youtube'>
                        <FaYoutube/>
                    </SocialIconLink> */}
                </SocialIcons>
                <DevelopedBy href='//sappitotech.com/' target='_blank' arial-label='Twitter'>Developed by Sappito Tech</DevelopedBy>
            </FooterWrapper>
        </FooterContainer>
    )
}
