import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core";
import "./App.css";
import { AppRouter } from "./routers/AppRouter";

let theme = createTheme({
  palette: {
    primary: {
      main: "#336699",
    },
    secondary: {
      main: "#63c18f",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    h2: {
      fontWeight: 500,
      letterSpacing: 2,
    },
    h3: {
      fontWeight: 500,
      letterSpacing: 2,
    },
  },
});

theme = responsiveFontSizes(theme);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppRouter />
    </ThemeProvider>
  );
}

export default App;
