import { Card, CardContent, Container, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { howStyles } from './howStyles'
import CodeIcon from '@material-ui/icons/Code';
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import ImportantDevicesOutlinedIcon from '@material-ui/icons/ImportantDevicesOutlined';

const useStyles = makeStyles((theme) => howStyles(theme))

export const HowSection = () => {

    const classes = useStyles()

    return (
        <Container id='how' className={ classes.container }>
            <Grid container
                className={ classes.grid }
                spacing={3}>
                    <Grid item xs={12}>
                        <Typography
                            className={ classes.title } 
                            variant='h3'
                            color='secondary'
                            align='center'>
                                ¿Cómo?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Card elevation={0}>
                            <CardContent className={ classes.card }>
                                <CodeIcon color='primary' style={{ fontSize: 70, width: '100%' }}/>
                                <Typography className={ classes.description } color='textSecondary' variant='body2' align='center'>
                                    Establecemos las bases correctas siempre pensando en el futuro. Código reutilizable, diseños sensibles y actuales. Eliminamos lo redundante para enfocarnos en lo importante.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Card elevation={0}>
                            <CardContent>
                                <DevicesOtherIcon color='primary' style={{ fontSize: 70, width: '100%' }}/>
                                <Typography className={ classes.description } color='textSecondary' variant='body2' align='center'>
                                    Nuestras interfaces son inteligentes, adaptables al medio, diseñadas a la medida. En el desarrollo de una nueva plataforma digital, se explora una amplia gama de resultados posibles con el fin de tomar las decisiones correctas.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Card elevation={0}>
                            <CardContent>
                                <QuestionAnswerOutlinedIcon color='primary' style={{ fontSize: 70, width: '100%' }}/>
                                <Typography className={ classes.description } color='textSecondary' variant='body2' align='center'>
                                    En todo proyecto trabajamos con el cliente, brindando contacto directo con nuestro equipo, permitiendo una comunicación clara y actualizaciones periódicas.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Card elevation={0}>
                            <CardContent>
                                <ImportantDevicesOutlinedIcon color='primary' style={{ fontSize: 70, width: '100%' }}/>
                                <Typography className={ classes.description } color='textSecondary' variant='body2' align='center'>
                                    Diseñamos y construimos aplicaciones para la web, iOS y Android. El usuario está siempre en el corazón de la estrategia.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
            </Grid>
        </Container>
    )
}
