import React, { useState } from 'react'
import { Navbar } from '../components/Navbar'
import { Sidebar } from '../components/Sidebar'
import { Footer } from '../components/Footer'
import { Intro } from '../components/Intro/Intro'
import '../App.css';
import { InfoSection } from '../components/InfoSection/InfoSection'
import { homeHow, homeWho } from '../components/InfoSection/Data'
import { HowSection } from '../components/HowSection/HowSection'
import { ContactSection } from '../components/ContactSection/ContactSection'


export const Home = () => {

    const [isOpen, setIsOpen] = useState(false)

    const toggleSidebar = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Sidebar isOpen={ isOpen } toggleSidebar={ toggleSidebar }/>
            <Navbar toggleSidebar={ toggleSidebar }/>
            <Intro />
            <div className='mainRaised'>
                <InfoSection { ...homeWho }/>
                <InfoSection { ...homeHow }/>
                <HowSection />
            </div>
            <ContactSection />
            <Footer />
        </>
    )
}
