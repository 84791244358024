
export const infoStyles = (theme) => ({
    container: {
        paddingLeft: 80,
        paddingRight: 80,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 60,
            paddingRight: 60,
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 30,
            paddingRight: 30,
        },
    },
    grid: {
        paddingTop: 40,
    },
    title: {
        marginBottom: 40,
    },
    description: {
        marginBottom: 20,
        letterSpacing: 2,
    },
    item: {
        // maxHeight: 400,
        marginTop: 40,
    },
    image: {
        width: '100%',
        paddingRight: 0,
    }
})


