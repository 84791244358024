import { Box, Container, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import { contactStyles } from './contactStyles'
import img from '../../images/contact_us.svg'
import logo from '../../images/sappito_tech_920.png'
import { ContactForm } from './ContactForm'

const useStyles = makeStyles((theme) => contactStyles(theme))

export const ContactSection = () => {
    const classes = useStyles()

    return (
        <Container id='where' className={ classes.container }>
            <Grid container spacing={4} justifyContent='center' alignItems='center'>
                <Grid item xs={12} md={6}>
                    <ContactForm classes={ classes }/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={6} justifyContent='center'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center'>
                                <img className={ classes.logo } src={ logo } alt='sappito tech'/>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Container>
                                <img className={ classes.image } src={ img } alt='sappito tech contacto'/>
                            </Container>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </Grid>
        </Container>
    )
}
