import aboutImg from '../../images/about.svg'
import whatImg from '../../images/how.svg'

export const homeWho = {
    id: 'who',
    headline: '¿Quiénes?',
    description: 'Somos una empresa en la que nos mueve la pasión por crear grandes proyectos, el apetito por el trabajo duro y la satisfacción de poder brindar a nuestros clientes aplicaciones que trascienden diseño y tecnología.',
    description2: 'Hemos creado un ambiente que fomenta el entusiasmo y permite a todos trabajar al máximo de su potencial.',
    imgStart: false,
    img: aboutImg,
    alt: 'sappito tech - quiénes'
}

export const homeHow = {
    id: 'what',
    headline: '¿Qué?',
    description: 'Con más de 8 años de experiencia en desarrollo de aplicaciones móviles (iOS - Android) y páginas web.',
    description2: 'Creamos productos y soluciones con los mejores estandares de calidad, siempre enfocados en las prioridades y necesidades de nuestros clientes. Vamos de la mano con nustros clientes desde el principio hasta el fin de cada proyecto, buscando obtener el mejor resultado posible.',
    imgStart: true,
    img: whatImg,
    alt: 'sappito tech - qué'
}