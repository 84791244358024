export const howStyles = (theme) => ({
    container: {
        paddingLeft: 80,
        paddingRight: 80,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 60,
            paddingRight: 60,
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
        },
    },
    grid: {
        paddingTop: 80,
        marginBottom: 40
    },
    card: {
        justifyContent: 'center'
    },
    title: {
        marginBottom: 40,
    },
    description: {
        marginTop: 30,
        letterSpacing: 2,
    },
})