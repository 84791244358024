import React from 'react'
import { CloseIcon, Icon, SidebarContainer, SidebarLink, SidebarMenu, SidebarWrapper } from './SidebarElements'

export const Sidebar = ({ isOpen, toggleSidebar }) => {
    return (
        <SidebarContainer isOpen={ isOpen } onClick={ toggleSidebar }>
            <Icon onClick={ toggleSidebar }>
                <CloseIcon/>
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to='who' onClick={ toggleSidebar } 
                    smooth={ true } duration={ 500 } spy={ true } exact='true' offset={ -80 }>
                        ¿Quiénes?
                    </SidebarLink>
                    <SidebarLink to='what' onClick={ toggleSidebar } 
                    smooth={ true } duration={ 500 } spy={ true } exact='true' offset={ -80 }>
                        ¿Qué?
                    </SidebarLink>
                    <SidebarLink to='how' onClick={ toggleSidebar } 
                    smooth={ true } duration={ 500 } spy={ true } exact='true' offset={ -80 }>
                        ¿Cómo?
                    </SidebarLink>
                    <SidebarLink to='where' onClick={ toggleSidebar } 
                    smooth={ true } duration={ 500 } spy={ true } exact='true' offset={ -80 }>
                        ¿Dónde?
                    </SidebarLink>
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}
