import React, { useEffect, useState } from 'react'
import { FaBars } from 'react-icons/fa'
import { LogoImg, MobileIcon, Nav, NavbarContainer, NavItem, NavLinks, NavLogo, NavMenu } from './NavbarElements'
import { animateScroll as scroll } from 'react-scroll'
import imgColor from '../../images/sappito_tech_920.png'
import imgBlanco from '../../images/sappito_tech_blanco_920.png'

export const Navbar = ({ toggleSidebar }) => {

    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
        <>
            <Nav scrollNav={ scrollNav }>
                <NavbarContainer>
                    <NavLogo to='/' onClick={ toggleHome }>
                        <LogoImg src={ (scrollNav ? imgBlanco : imgColor) } /> 
                    </NavLogo>
                    <MobileIcon onClick={ toggleSidebar } scrollNav={ scrollNav }>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu scrollNav={ scrollNav }>
                        <NavItem>
                            <NavLinks to="who" smooth={ true } duration={ 500 } spy={ true } exact='true' offset={ -80 }>
                                ¿Quiénes?
                            </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="what" smooth={ true } duration={ 500 } spy={ true } exact='true' offset={ -80 }>
                                ¿Qué?
                            </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="how" smooth={ true } duration={ 500 } spy={ true } exact='true' offset={ -80 }>
                                ¿Cómo?
                            </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="where" smooth={ true } duration={ 500 } spy={ true } exact='true' offset={ -80 }>
                                ¿Dónde?
                            </NavLinks>
                        </NavItem>
                    </NavMenu>
                </NavbarContainer>
            </Nav>
        </>
    )
}
