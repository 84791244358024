import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import React from 'react'
import { Parallax } from 'react-parallax';

const img = 'https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'


const useStyles = makeStyles((theme) => ({
    title: {
        textShadow: '1px 1px 5px #a3a3a3',
        color: 'white'
    }
}))

export const Intro = () => {

    const classes = useStyles()

    return (
        <div>
            <Parallax blur={2} bgImage={ img } bgImageAlt="sappito tech" strength={400}>
                <Container maxWidth={false}>
                    <Box 
                    display='flex'
                    minHeight={900}
                    alignItems='center'
                    justifyContent='center'
                    // maxWidth='xs'
                    margin='auto'
                    >
                        <Typography 
                            className={ classes.title }
                            variant='h2' 
                            component='h1'
                            align='center'>
                                Diseñamos, Desarrollamos, Creamos
                        </Typography>
                    </Box>
                </Container>
            </Parallax>
        </div>
        
    )
}
