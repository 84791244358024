import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Home } from "../pages/index";
import { Privacy } from "../pages/Privacy";
import { PublicRoute } from "./PublicRoute";

export const AppRouter = () => {
  return (
    <Router>
      <div>
        <Switch>
          <PublicRoute exact path="/" component={Home} />
          <PublicRoute exact path="/privacy" component={Privacy} />
          {/* <Redirect to="/" /> */}
        </Switch>
      </div>
    </Router>
  );
};
